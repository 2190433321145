.dict-container{ 
  display: flex; 
  width: 100%; 
  height: 100vh;
  flex-direction: column; 
  overflow: hidden;
} 

.dict-container2{
  display: flex; 
  width: 100%; 
  height: 100vh;
  flex-direction: column; 
  overflow: hidden;
} 
.dict-iframe {
  flex: 1 1 auto;
  border: 0;
 }