.codexWrapper{
  display: flex;
  justify-content: space-around;
}
.codexImage{
  //width: 550px;
  height: 100vh;
  overflow: scroll;
}
.codexContentsWrapper{
  width: 400px;
  height: 100vh;
  overflow: scroll
}
.surfaceInfo{
  width: 400px;
  height: 100vh;
  overflow: scroll
}
.codexLink{
  color: $link-color;
  cursor: pointer;
}
