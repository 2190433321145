.etitle{
  border-left: 3px solid blue;
  padding-left: 5px;
}
.mtitle{
  border-left: 3px solid red;
  padding-left: 5px;

}
.ttitle{
  border-left: 3px solid purple;
  padding-left: 5px;

}
.citation-manifestation-options{

}
