@import "variables";
@import "base";
@import "Footer";
@import "Home";
@import "Surface2";
@import "Surface3Wrapper";
@import "Text";
@import "Window";
@import "Collection";
@import "TextOutline";
@import "Citation";
@import "Codex";
@import "TextSwitch";
@import "OSDInstance";
@import "Dictionary";
@import "Comments2";
@import "Search";
@import "VersionChain";
@import "TextCompareItem";

body {
  margin: 0;
  margin-top: 56px;
  margin-bottom: 56px;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // these properties needed on body to get "always on bottom footer" (see https://codepen.io/cbracco/pen/zekgx)
  // position: relative;
  // padding-bottom: 6rem;
  // min-height: 100%;
}


// global setting for tables sets min width on first column of table
table.table.table-striped tr > td:first-child {
    width:20%;
}

// global setting for hidden classs
.hidden{
  display: none;
}

.currentlyViewing{
  font-weight: bold;
}
//TODO: it would be better if this just inherited the bootstrap link style
.lbp-span-link{
  color: $link-color;
  cursor: pointer;
}
.lbp-span-link:hover{
  color: darken($link-color,20%);
  cursor: pointer;
  text-decoration: underline;
}

