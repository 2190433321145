@import "variables";
.timelineList {margin:  1%; padding:  2%;}
.Political {color: Red;}
.Benefices {color: Blue;}
.Life {color: Green;}
.Education {color: Purple;}
.timeline-band-0 .timeline-ether-bg { background-color: $light }
.timeline-band-1 .timeline-ether-bg { background-color: #528252 }
.t-highlight1 {font-size: 16px; font-color: black;}
#history-timeline {height: 400px; width: 700px; margin: auto;}
//.bib {margin: 4px; font-size:14px;}
//.bibLive {cursor: pointer; margin: 4px; font-size:14px;}
//.bibLive:hover {text-decoration: underline;}
//a.bibLive {cursor: pointer; color: blue;}
.timeline-event-label{font-size: 12px;}

#bioCommentBox{
display: none;
position: absolute;
width: 400px;
max-height: 300px;
scroll: auto;
overflow: scroll;
padding:  5px;
font-size: 12px;


/*rounded corners */

-moz-border-radius: 2px;
-webkit-border-radius: 2px;
border-radius: 2px;

/* Background color and gradients */

background: $dark;
background: -moz-linear-gradient(top, $dark, $light);
background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($dark), to($light));

/* Borders */

border: 2px solid $dark;

-moz-box-shadow:inset 0px 0px 1px #edf9ff;
-webkit-box-shadow:inset 0px 0px 1px #edf9ff;
box-shadow:inset 0px 0px 1px #edf9ff;}

.showComment {font-size: 12px; cursor: pointer}
.hideCommentBox, .shrinkCommentBox
{
font-size: 12px; cursor: pointer; color: blue;
}


textarea.bioComInput, input.bioComInput{}

.bibentry, .googlelink{font-size: 14px;}

.eventHeader {margin: 0; padding: 4px; margin: 0; cursor: pointer}
.eventViewing {background-color: $dark; font-weight: bolder;}
.eventHeader:hover {background-color:$dark;}
.eventBody{
  //display: none;
  border-bottom:  1px solid gray; padding: 0px; border-left:  1px dotted gray;}
.event{border-bottom: 1px solid gray; margin-bottom: 0px; padding: 0;}
.eventDesc{padding:  5px; margin: 0; background-color:$light}
.eventComments{padding:  0; margin: 0; background-color: lighten($dark, 10%); font-size: 12px;}
.eventDescPara {margin-bottom: 2px; padding-bottom: 4px; border-bottom: 2px dashed white;}
a.timelineCrossReference {color: blue;}
a.eventList {font-size: 16px;}
a.bibentry, a.googlelink {padding: 0 3px 0 3px;}
span.Category {color: white; padding: 0 3px 0 3px; font-size: 12px; background-color: black; opacity: .7}
span.Education {background-color: green;}
span.Political {background-color: red;}
span.Benefices {background-color: blue;}
span.Sentences {background-color: purple;}
span.BlanchardAffair {background-color: orange;}
span.Life {background-color: gray;}
//p {margin: 2px;}
//h1, h2 {margin: 5px;}
