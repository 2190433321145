//surface navigation controls
.surface-navigation{
  display: flex;
  justify-content: flex-start;
  width: 1000px;
  text-align: center
}
.surface-navigation div button{
  margin: 0 5px;
}
.surface-navigation p{
  padding: 0 10px
}
