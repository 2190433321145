.Home {
  text-align: center;

  height: 100%;
}

#lbp-home{
  padding-top: 50px;
  background: #fff url("/coverimage.jpg") repeat center top;
  height: 90vh;

}

#lbp-jumbo{

  opacity: .8;
  background-color: $light
}
