.sideWindow{
  position: fixed;
  left: 0;
  top: 0;
  background-color: $dark;
  z-index: 1;
  margin-top: 56px;
  height: 100vh;
  overflow: scroll;
  transition: width 200ms;
  box-shadow: 0px 0px 5px black;
  padding-bottom: 40%;
}
.sideWindowmiddle{
  width: 40%;
  transition: width 200ms;
}
.sideWindowminimum{
  width: 56px;
  transition: width 200ms;
}
.sideWindowmaximum{
  width: 100%;
  transition: width 200ms;
}
.bottomWindow{
  //margin-top: 56px;
  //margin-bottom: 56px;
  position: fixed;
  background-color: $dark;
  z-index: 2;
  width: 100%;
  overflow-y: scroll;
  transition: width 200ms;
  box-shadow: 0px 0px 5px black;

}
.bottomWindowmiddle{
  height: 40%;
  bottom: 56px;
  transition: height 200ms;
}
.bottomWindowminimum{
  height: 56px;
  bottom: 56px;
  transition: height 200ms;
}
.bottomWindowmaximum{
  height: calc(100% - 112px);
  bottom: 56px;
  transition: height 200ms;
}
// .BottomWindowClosed{
//   width: 0;
//   transition: 'width 0.5s'
// }
