@import "variables";

.bg-light {
  background-color: $light !important;
}
.bg-dark {
  background-color: $dark !important;
}

p {
  margin-bottom: .25rem;
}